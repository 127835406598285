import React from "react";
import classes from "./Button.module.css";

const Button = ({ text, icon ,onBtnClick}) => {
  return (
    <button className={classes.btn} type="button" onClick={onBtnClick}>
      {text}
      <i className={`${icon} ${classes.btn_icon}`}></i>
    </button>
  );
};

export default Button;
