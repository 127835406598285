import React from "react";
import classes from "./TermsPage.module.css";
import { useNavigate } from "react-router-dom";

const TermsPage = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.main_container}>
      <div className={classes.message_container}>
        <div className={classes.message}>
          <p className={classes.message_text}>Page Under Development!</p>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className={classes.back_btn}
          >
            Go Back!
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
