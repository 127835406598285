import React from "react";
import classes from "./Layout.module.css";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../slices/sidebarSlice";
import { useNavigate } from "react-router-dom";
import SmallLoader from "./Loaders/SmallLoader";

const Layout = ({
  children,
  chatHistoryLoading,
  chatHistory,
  expand,
  chat_id,
  style,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const navigateHandler = (route) => {
    navigate(route);
  };

  return (
    <div className={`${classes.container} ${!expand && classes.expand_false}`}>
      <div
        className={`${classes.sidebar_container} ${
          !expand && classes.expand_false
        }`}
      >
        <div
          className={`${classes.sidebar_content} ${
            !expand && classes.expand_false
          }`}
        >
          <div className={classes.sidebar_header}>
            <button
              className={classes.menu}
              onClick={() => dispatch(toggleSidebar())}
            >
              <i className={`pi pi-bars ${classes.menu_icon}`}></i>
            </button>
          </div>

          <button
            className={classes.new_chat_btn}
            type="button"
            onClick={() => navigateHandler("/app")}
          >
            <i className={`pi pi-plus ${classes.plus_icon}`} ></i>
            <p className={classes.text}>New Chat</p>
          </button>

          <div
            className={`${classes.line_breaker}  ${
              !expand && classes.expand_false
            }`}
          ></div>

          <div
            className={`${classes.chat_history} ${
              !expand && classes.expand_false
            }`}
          >
            {chatHistoryLoading ? (
              <SmallLoader />
            ) : (
              chatHistory?.map((chat, idx) => {
                return (
                  <button
                    className={`${classes.chat_box} ${
                      chat_id == chat?.chat_id && classes.active
                    }`}
                    type="button"
                    key={idx}
                    onClick={() => navigateHandler(`/app/${chat?.chat_id}`)}
                  >
                    <i
                      className={`pi pi-comment ${classes.chat_icon}`}
                    ></i>
                    <p className={classes.chat_box_text}>{chat?.chat_name}</p>
                  </button>
                );
              })
            )}
          </div>

          <div
            className={`${classes.sidebar_menu_container} ${
              !expand && classes.expand_false
            }`}
          >
            {/* <button
              type="button"
              className={classes.faq_btn}
              onClick={() => navigateHandler("/faq")}
            >
              <i
                className={`fa-solid fa-circle-question ${classes.faq_icon}`}
              ></i>
              <p className={classes.text}>FAQ</p>
            </button> */}
            <button
              type="button"
              className={classes.recent_btn}
              onClick={() => navigateHandler("/recent")}
            >
              <i className={`pi pi-clock ${classes.recent_icon}`}></i>
              <p className={classes.text}>Recent</p>
            </button>
          </div>
        </div>
      </div>
      <div className={classes.main_container} style={style}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
