import React, { useEffect } from "react";

const UserLocation = ({
  children,
  setLocationPermission,
  setLatitude,
  setLongitude,
}) => {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    setLatitude(crd.latitude);
    setLongitude(crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          setLocationPermission(result.state);

          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            // console.log(
            //   "Show Instructions for Location Permission how to grant..."
            // );
          }
        });
    } else {
    //   console.log("Geolocation is not supported by this browser...");
    }
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};

export default UserLocation;
