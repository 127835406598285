import React, { useEffect, useState } from "react";
import classes from "./RecentPage.module.css";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserChatHistoryApi } from "../Api/api";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Button from "../Components/Button";

const RecentPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const onBtnClick = (id) => {
    navigate(`/app/${id}`);
  };
  return (
    <Layout
      expand={expand}
      chatHistoryLoading={chatHistoryLoading}
      chatHistory={chatHistory}
      chat_id={null}
      style={{ maxHeight: "unset" }}
    >
      <Navbar
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={null}
      />
      <div className={classes.main_container}>
        <div className={classes.recent_history_container}>
          {chatHistory?.slice(0,20)?.map((chat, idx) => {
            return (
              <div className={classes.history_container} key={idx}>
                <p className={classes.chat_name}>
                  <span className={classes.bold}>Chat Name: </span>
                  {chat?.chat_name}
                </p>
                <p className={classes.datetime}>
                  <span className={classes.bold}>Date: </span>
                  {moment(chat?.date_time).format("YYYY-MM-DD")}
                </p>
                <Button
                  text="Open Conversation"
                  icon="pi pi-arrow-right"
                  onBtnClick={() => onBtnClick(chat?.chat_id)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default RecentPage;
