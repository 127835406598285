import React from "react";
import { motion } from "framer-motion";
import CategoryItem from "./CategoryItem";
import classes from "./CategoryDragSection.module.css";

const CategoryDragSection = ({
  data,
  dataItemKeys,
  category,
  onClickHandler,
  slice,
  cardType
}) => {
  return (
    <motion.div
      className={classes.category}
      drag="x"
      dragConstraints={{
        top: 0,
        left: slice ? -1800 : data.length > 5 ? data.length * -180 : data.length * -150,
        right: 0,
        bottom: 0,
      }}
      transition={{
        type: "spring",
        damping: 100,
        stiffness: 100,
      }}
    >
      {data.length > 0 &&
        data?.slice(0, slice ? 8 : -1)?.map((dataItem, index) => {
          return (
            <CategoryItem
              dataItem={dataItem}
              key={index}
              dataItemKeys={dataItemKeys}
              category={category}
              onClickHandler={onClickHandler}
              cardType={cardType}
            />
          );
        })}
    </motion.div>
  );
};

export default CategoryDragSection;
