import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Auth = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const { user } = useSelector((state) => state?.userSlice);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      setAuth(1);
    }
  }, []);

  return <>{auth === 1 && children}</>;
};

export default Auth;
