import React, { useEffect, useState } from "react";
import classes from "./ChatVoiceModal.module.css";
import Lottie from "lottie-react";
import MicroPhoneAnimation from "../../Animations/Microphone.json";
import { motion, useAnimation } from "framer-motion";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const ChatVoiceModal = ({
  setChatVoiceModal,
  chatVoiceModal,
  handleMessageSubmit,
}) => {
  const controls = useAnimation();
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    setError(true);
  }

  const variants = {
    hidden: {
      opacity: 0,
      display: "none",
      scale: 1.1,
    },
    visible: {
      opacity: 1,
      display: "flex",
      scale: 1,
    },
  };

  useEffect(() => {
    if (chatVoiceModal) {
      resetTranscript();
      setMessage("");
      controls.start("visible");
      SpeechRecognition.startListening({
        continuous: true,
      });
    } else {
      stopVoiceListener();
      controls.start("hidden");
    }
  }, [chatVoiceModal, SpeechRecognition]);

  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  const stopVoiceListener = () => {
    SpeechRecognition.stopListening();
  };

  const submitMessage = (e) => {
    handleMessageSubmit(e,message);
    setChatVoiceModal(false);
  };

  return (
    <motion.div
      variants={variants}
      animate={controls}
      initial="hidden"
      transition={{
        type: "spring",
        damping: 100,
        stiffness: 1000,
      }}
      className={classes.modal_overlay}
      onClick={() => setChatVoiceModal(false)}
    >
      <motion.div
        className={classes.modal}
        variants={variants}
        animate={controls}
        initial="hidden"
        transition={{
          duration: 0.5,
          type: "spring",
          damping: 100,
          stiffness: 1000,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {error ? (
          <div className={classes.message_container}>
            <p className={classes.message}>
              Sorry!, Your Browser does not support the microphone...
            </p>
          </div>
        ) : (
          <>
            <div className={classes.modal_header}>
              <Lottie
                loop
                autoPlay
                animationData={MicroPhoneAnimation}
                className={classes.animation}
              />
              <p className={classes.header_text}>Speak Now</p>
            </div>

            {(transcript || message) && (
              <div className={classes.message_wrapper}>
                <textarea
                  type="text"
                  placeholder="Message Visiontrek AI"
                  className={classes.message_box}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                <button
                  className={`${classes.arrow_wrapper} ${
                    (message != "" ||
                      message.length != 0 ||
                      message.trim().length != 0) &&
                    classes.active
                  }`}
                  onClick={(e) => submitMessage(e)}
                  type="button"
                  disabled={
                    message == "" ||
                    message.length <= 0 ||
                    message.trim().length <= 0
                  }
                >
                  <i
                    className={`pi pi-arrow-up ${classes.arrow_up_icon}`}
                  ></i>
                </button>
              </div>
            )}
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ChatVoiceModal;
