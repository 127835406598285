import React from "react";
import classes from "./Carousel.module.css";
import CategoryTitle from "./Category-Section/CategoryTitle";
import OwlCarousel from "react-owl-carousel";

const Carousel = ({ categoryData,options }) => {
  return (
    <>
      {categoryData?.map((dataItem, i) => {
        const { videos, category } = dataItem;
        return (
          <div className={classes.carousel_section} key={i}>
            <CategoryTitle title={category} />
            <OwlCarousel
              className={`${classes.carousel} owl-theme`}
              {...options}
            >
              {videos.map((data, idx) => {
                return (
                  <div key={idx} className={classes.carousel_item}>
                    <div className={classes.carousel_image}>
                      <img
                        src={data?.imgurl}
                        alt={data?.name}
                        className={classes.image}
                      />
                      <div className={classes.image_bg}></div>
                      <button className={classes.play_btn}>Watch</button>
                    </div>
                    <div className={classes.carousel_content}>
                      <p className={classes.name}>{data?.name}</p>
                      <p className={classes.duration}>{data?.duration}</p>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        );
      })}
    </>
  );
};

export default Carousel;
