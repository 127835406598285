import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import ChatPage from "../Pages/ChatPage";
import ChatIdPage from "../Pages/ChatIdPage";
import Auth from "../Components/Auth/Auth";
import CategoryPage from "../Pages/CategoryPage";
import CategoryPlayPage from "../Pages/CategoryPlayPage";
import BlogsPage from "../Pages/BlogsPage";
import BlogPage from "../Pages/BlogPage";
import ProfilePage from "../Pages/ProfilePage";
import RecentPage from "../Pages/RecentPage";
import TermsPage from "../Pages/TermsPage";
import ErrorHandler from "../Components/ErrorHandler";
import ErrorBoundary from "../Pages/ErrorBoundary";
import MobileVersion from "../Pages/MobileVersion/MobileVersion";
import FreeChatDemoPage from "../Pages/FreeChatDemoPage";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/app",
      element: (
        <Auth>
          <ChatPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/app/:chat_id",
      element: (
        <Auth>
          <ChatIdPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/app/category",
      element: (
        <Auth>
          <CategoryPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/app/category/play",
      element: (
        <Auth>
          <CategoryPlayPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/app/blogs",
    //   element: (
    //     <Auth>
    //       <BlogsPage />
    //     </Auth>
    //   ),
    //   errorElement: <ErrorBoundary />,
    // },
    // {
    //   path: "/app/blogs/:blogId",
    //   element: (
    //     <Auth>
    //       <BlogPage />
    //     </Auth>
    //   ),
    //   errorElement: <ErrorBoundary />,
    // },
    {
      path: "/profile",
      element: (
        <Auth>
          <ProfilePage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/recent",
      element: (
        <Auth>
          <RecentPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/faq",
    //   element: (
    //     <Auth>
    //       <FAQPage />
    //     </Auth>
    //   ),
    // },
    {
      path: "/terms",
      element: <TermsPage />,
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/mobile-version",
    //   element: <MobileVersion />,
    //   errorElement: <ErrorBoundary />,
    // },
    {
      path: "/demo",
      element: <FreeChatDemoPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: (
        <ErrorHandler
          icon="pi pi-ban"
          type="page-not-found"
          message="Page Not Found!"
        />
      ),
      errorElement: <ErrorBoundary />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routing;
