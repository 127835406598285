import React from "react";
import classes from "./CategoryMenu.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const CategoryMenu = ({ leftConstraint }) => {
  const navigate = useNavigate();

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <motion.div
      className={classes.top_menu_bar}
      drag="x"
      dragConstraints={{
        top: 0,
        left: leftConstraint,
        right: 0,
        bottom: 0,
      }}
      transition={{
        type: "spring",
        damping: 100,
        stiffness: 100,
      }}
    >
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app")}
      >
        Chat With AI
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=news")}
      >
        News
      </button>
      {/* <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=cartoons")}
      >
        Cartoons
      </button> */}
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=games")}
      >
        Games
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=exercise")}
      >
        Exercise
      </button>
    </motion.div>
  );
};

export default CategoryMenu;
