import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment-timezone";

const initialState = {
  chat: [],
  chatDate: null,
};

const freeChatSlice = createSlice({
  name: "freeChatSlice",
  initialState: initialState,
  reducers: {
    setFreeUserChat: (state, action) => {
      if (_.isArray(action.payload)) {
        console.log("is array true");
        state.chat = action.payload;
      } else {
        console.log("is array false");
        state.chat = [...state.chat, action.payload];
      }

      return state;
    },
    setFreeChatDate: (state, action) => {
      const chatDate = state.chatDate;

      if (chatDate != null) {
        const date = chatDate;
        const currentDate = moment();

        if (moment(date).isSame(currentDate, "day")) {
          state.chatDate = moment();
        } else {
          state.chat = [];
          state.chatDate = moment();
        }
      } else {
        state.chatDate = moment();
      }
      return state;
    },
  },
});

export const { setFreeUserChat, setFreeChatDate } = freeChatSlice.actions;
export default freeChatSlice;
