import React, { useEffect, useRef, useState } from "react";
import classes from "./ChatPage.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ChatApi,
  scrapGoogleDataApi,
  UserChatByChatIdApi,
  UserChatHistoryApi,
} from "../Api/api";
import DotLoader from "../Components/Loaders/DotLoader";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import ChatBox from "../Components/Chat-Box/ChatBox";
import ChatInputBox from "../Components/Chat-Box/ChatInputBox";
import ChatVoiceModal from "../Components/Chat-Box/ChatVoiceModal";
import UserLocation from "../Components/UserLocation";

const ChatIdPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const [messages, setMessages] = useState([]);
  const { chat_id } = useParams();
  const { user } = useSelector((state) => state?.userSlice);
  const [message, setMessage] = useState("");

  const [locationPermission, setLocationPermission] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [chatHistoryLoading, setChatHistoryLoading] = useState(true);
  const [chatLoading, setChatLoading] = useState(true);
  const [aiChatLoading, setAiChatLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatVoiceModal, setChatVoiceModal] = useState(false);

  const chatRef = useRef(null);
  useEffect(() => {
    if (!chatLoading && chatRef?.current) {
      chatRef?.current.scrollTo({
        top: chatRef?.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatRef, chatLoading, aiChatLoading]);

  useEffect(() => {
    fetchChatHistory();
    fetchUserChat();
  }, [chat_id]);

  const fetchUserChat = async () => {
    try {
      setChatLoading(true);
      const response = await UserChatByChatIdApi(
        { chat_id: chat_id, msisdn: user?.msisdn },
        user?.token
      );
      setMessages(response?.data?.result);
      setChatLoading(false);
    } catch (error) {
      setChatLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const handleMessageSubmit = async (e, query) => {
    e.preventDefault();
    setAiChatLoading(true);
    if (query) {
      setMessages((prevValue) => {
        return [
          ...prevValue,
          {
            request: JSON.stringify(query),
            response: "",
            user_id: user?.id,
            msisdn: user?.msisdn,
          },
        ];
      });
    } else {
      setMessages((prevValue) => {
        return [
          ...prevValue,
          {
            request: JSON.stringify(message),
            response: "",
            user_id: user?.id,
            msisdn: user?.msisdn,
          },
        ];
      });
    }

    setMessage("");

    try {
      const response = await ChatApi(
        {
          user_id: user?.id,
          msisdn: user?.msisdn,
          chat_id: chat_id,
          message: query ? query : message,
          latitude: latitude,
          longitude: longitude,
          locationPermission: locationPermission,
        },
        user?.token
      );
      let newMessages = messages.slice(0, messages.length);

      setMessages([...newMessages, response?.data?.result]);
      setAiChatLoading(false);
    } catch (error) {
      setAiChatLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  return (
    <UserLocation
      setLatitude={setLatitude}
      setLongitude={setLongitude}
      setLocationPermission={setLocationPermission}
    >
      <Layout
        expand={expand}
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={chat_id}
      >
        <Navbar
          chatHistoryLoading={chatHistoryLoading}
          chatHistory={chatHistory}
          chat_id={chat_id}
        />

        <div className={classes.chat_container}>
          <ChatBox
            aiChatLoading={aiChatLoading}
            chatLoading={chatLoading}
            messages={messages}
            showCategories={true}
          />
          <ChatInputBox
            message={message}
            setMessage={setMessage}
            handleMessageSubmit={handleMessageSubmit}
            showCategories={true}
            setChatVoiceModal={setChatVoiceModal}
          />

          <ChatVoiceModal
            setChatVoiceModal={setChatVoiceModal}
            chatVoiceModal={chatVoiceModal}
            handleMessageSubmit={handleMessageSubmit}
          />
        </div>
      </Layout>
    </UserLocation>
  );
};

export default ChatIdPage;
