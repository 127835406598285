import React, { useState } from "react";
import classes from "./CategoryNews.module.css";
import moment from "moment-timezone";
import { motion } from "framer-motion";

const CategoryNews = ({ data, onClickHandler, slice, newsActive }) => {
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  console.log(data,'d');

  const handleMouseDown = (event) => {
    setStartPosition({ x: event.clientX, y: event.clientY });
    setIsDragging(false);
  };

  const handleMouseUp = (event, data, idx, navigateToNews) => {
    event.stopPropagation();
    const distance = Math.sqrt(
      Math.pow(event.clientX - startPosition.x, 2) +
        Math.pow(event.clientY - startPosition.y, 2)
    );
    if (distance < 5 && !isDragging) {
      onClickHandler(data, idx, navigateToNews ?? false);
    }
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  return (
    <motion.div
      className={classes.category_news}
      drag="x"
      dragConstraints={{
        top: 0,
        // left: -1800,
        left: slice
          ? -1800
          : data.length > 5
          ? data.length * -220
          : data.length * -150,
        right: 0,
        bottom: 0,
      }}
      transition={{
        type: "spring",
        damping: 100,
        stiffness: 100,
      }}
    >
      {data?.length > 0 &&
        data.slice(0, 8)?.map((dataItem, idx) => {
          return (
            <motion.div
              layout
              animate={
                newsActive?.title === dataItem?.title && newsActive?.idx === idx
                  ? {
                      maxWidth: "unset",
                      width: "100%",
                      height: 450,
                      maxHeight: 500,
                      opacity: 1,
                      filter: "blur(0)",
                      zIndex: 99,
                    }
                  : {
                      maxWidth: 250,
                      minWidth: 250,
                      minHeight: 350,
                      maxHeight: 350,
                      opacity: 1,
                      filter: "unset",
                    }
              }
              className={classes.news}
              key={idx}
              onMouseDown={handleMouseDown}
              onMouseUp={(event) => handleMouseUp(event, dataItem, idx)}
              onMouseMove={handleMouseMove}
            >
              {dataItem?.image ? (
                <div className={classes.news_image_container}>
                  <img
                    src={dataItem?.image}
                    alt="news"
                    className={classes.news_image}
                    onError={(e) => (e.target.src = "/assets/images/no-image.jpg")}
                  />
                </div>
              ) : (
                <div className={classes.news_image_container}>
                  <img
                    src="/assets/images/no-image.jpg"
                    alt="news"
                    className={classes.news_image}
                  />
                </div>
              )}

              <div className={classes.news_content}>
                <p
                  className={`${classes.news_title} ${
                    newsActive?.title === dataItem?.title &&
                    newsActive?.idx === idx &&
                    classes.active
                  }`}
                >
                  {dataItem?.title}
                </p>
                <p
                  className={`${classes.news_snippet} ${
                    newsActive?.title === dataItem?.title &&
                    newsActive?.idx === idx &&
                    classes.active
                  }`}
                >
                  {dataItem?.description
                    ? dataItem?.description
                    : dataItem?.short_description}
                </p>
              </div>

              {newsActive?.title === dataItem?.title &&
                newsActive?.idx === idx && (
                  <div className={classes.more_information_container}>
                    <p
                      className={classes.news_link}
                      onMouseDown={handleMouseDown}
                      onMouseUp={(event) =>
                        handleMouseUp(event, dataItem, idx, true)
                      }
                      onMouseMove={handleMouseMove}
                      // onClick={() => navigateToNews(dataItem?.url)}
                    >
                      <span className={classes.bold}>Link: </span>
                      {dataItem?.url}
                    </p>
                  </div>
                )}

              <div className={classes.actions}>
                <p className={classes.date_time}>
                  {moment(dataItem?.published).fromNow()}
                </p>
                <button type="button" className={classes.link}>
                  <i
                    className={`pi
                         ${
                           newsActive?.title === dataItem?.title &&
                           newsActive?.idx === idx
                             ? "pi-arrow-up"
                             : "pi-arrow-right"
                         } ${classes.arrow_link}`}
                  ></i>
                </button>
              </div>
            </motion.div>
          );
        })}
    </motion.div>
  );
};

export default CategoryNews;
