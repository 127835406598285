import React, { useEffect, useState } from "react";
import classes from "./ProfilePage.module.css";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import { toast } from "react-toastify";
import { FetchUserBlogsApi, UserChatHistoryApi } from "../Api/api";
import { useDispatch, useSelector } from "react-redux";
import CategoryTitle from "../Components/Category-Section/CategoryTitle";
import { logoutUser } from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
import SmallLoader from "../Components/Loaders/SmallLoader";
import CategoryBlogs from "../Components/Category-Section/CategoryBlogs";

const ProfilePage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  // const [loading, setLoading] = useState(true);

  // const [blogs, setBlogs] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchChatHistory();
    // fetchUserBlog();
  }, []);

  // const fetchUserBlog = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await FetchUserBlogsApi(
  //       { user_id: user?.id },
  //       user?.token
  //     );
  //     setBlogs(response?.data?.result);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(
  //       error?.response?.data?.err ||
  //         error?.data?.err ||
  //         error?.message ||
  //         error
  //     );
  //   }
  // };

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const logoutHandler = () => {
    dispatch(logoutUser());
    toast.success("Logout Successfully...");
    navigate("/");
  };

  return (
    <Layout
      expand={expand}
      chatHistoryLoading={chatHistoryLoading}
      chatHistory={chatHistory}
      chat_id={null}
      style={{ maxHeight: "unset" }}
    >
      <Navbar
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={null}
      />
      <div className={classes.main_container}>
        <div className={classes.profile_container}>
          <div className={classes.avatar_image_container}>
            <img
              src="/assets/images/profile-avatar.png"
              alt="avatar"
              className={classes.avatar_image}
            />
          </div>
          <p className={classes.label}>Your Profile Account No.</p>
          <p className={classes.detail}>{user?.msisdn}</p>
          <button
            type="button"
            className={classes.log_out_btn}
            onClick={() => logoutHandler()}
          >
            Logout
          </button>
          {/* <CategoryTitle title="Your Blogs" /> */}
          {/* {loading ? (
            <SmallLoader />
          ) : (
            <React.Fragment>
              <CategoryBlogs data={blogs} slice={false} />
            </React.Fragment>
          )} */}
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
