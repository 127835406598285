import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import sidebarSlice from "./slices/sidebarSlice";
import drawerSlice from "./slices/drawerSlice";
import dataSlice from "./slices/dataSlice";

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import freeChatSlice from "./slices/freeChatSlice";


const rootReducer = combineReducers({
  // here add reducers which we want to persist...
  freeChatSlice:freeChatSlice.reducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    persisted: persistedReducer,
    userSlice: userSlice.reducer,
    sidebarSlice: sidebarSlice.reducer,
    drawerSlice: drawerSlice.reducer,
    dataSlice: dataSlice.reducer,
  },
});

export default store;
export const persistor = persistStore(store);
