import React, { useEffect, useState } from "react";
import classes from "./ChatInputBox.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ChatInputBox = ({
  message,
  handleMessageSubmit,
  setMessage,
  showCategories,
  setChatVoiceModal,
}) => {
  const [leftConstraint, setLeftConstraint] = useState();
  useEffect(() => {
    const updateConstraints = () => {
      if (window.innerWidth < 550) {
        setLeftConstraint(-250);
      } else {
        setLeftConstraint(0);
      }
    };

    updateConstraints();
    window.addEventListener("resize", updateConstraints);

    return () => {
      window.removeEventListener("resize", updateConstraints);
    };
  }, []);

  const navigate = useNavigate();

  const navigateHandler = (path) => {
    navigate(path);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSubmit(e);
    }
  };

  return (
    <div
      className={classes.message_box_container}
      style={showCategories && { minHeight: "160px", maxHeight: "160px" }}
    >
      {showCategories && (
        <motion.div
          className={classes.drag_categories}
          drag="x"
          dragConstraints={{
            top: 0,
            left: leftConstraint,
            right: 0,
            bottom: 0,
          }}
          transition={{
            type: "spring",
            damping: 100,
            stiffness: 100,
          }}
        >
          <div
            className={classes.category}
            onClick={() => navigateHandler("/app/category?cid=news")}
          >
            <p className={classes.category_name}>News</p>
          </div>
          {/* <div
            className={classes.category}
            onClick={() => navigateHandler("/app/category?cid=cartoons")}
          >
            <p className={classes.category_name}>Cartoons</p>
          </div> */}
          <div
            className={classes.category}
            onClick={() => navigateHandler("/app/category?cid=games")}
          >
            <p className={classes.category_name}>Games</p>
          </div>
          {/* <div
            className={classes.category}
            onClick={() => navigateHandler("/app/blogs")}
          >
            <p className={classes.category_name}>Blogs</p>
          </div> */}
          <div
            className={classes.category}
            onClick={() => navigateHandler("/app/category?cid=exercise")}
          >
            <p className={classes.category_name}>Exercise</p>
          </div>
        </motion.div>
      )}
      <div className={classes.message_wrapper}>
        <button
          className={`${classes.voice_button} ${
            (message != "" ||
              message.length != 0 ||
              message.trim().length != 0) &&
            classes.inactive
          }`}
          onClick={() => setChatVoiceModal(true)}
          type="button"
          disabled={
            message != "" || message.length > 0 || message.trim().length > 0
          }
        >
          <i className={`pi pi-microphone ${classes.arrow_up_icon}`}></i>
        </button>
        <textarea
          type="text"
          placeholder="Message Digitize AI"
          className={classes.message_box}
          // onChange={(e) => setMessage(e.target.value)}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
          value={message}
        />
        <button
          className={`${classes.arrow_wrapper} ${
            (message != "" ||
              message.length != 0 ||
              message.trim().length != 0) &&
            classes.active
          }`}
          onClick={(e) => handleMessageSubmit(e, false)}
          // type="button"
          type="submit"
          disabled={
            message == "" || message.length <= 0 || message.trim().length <= 0
          }
        >
          <i className={`pi pi-arrow-up ${classes.arrow_up_icon}`}></i>
        </button>
      </div>
    </div>
  );
};

export default ChatInputBox;
