import React, { useState } from "react";
import classes from "./CategoryItem.module.css";

const CategoryItem = ({
  dataItem,
  dataItemKeys,
  category,
  onClickHandler,
  cardType,
}) => {
  const { imgurl, name } = dataItemKeys;

  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (event) => {
    setStartPosition({ x: event.clientX, y: event.clientY });
    setIsDragging(false);
  };

  const handleMouseUp = (event, data) => {
    const distance = Math.sqrt(
      Math.pow(event.clientX - startPosition.x, 2) +
        Math.pow(event.clientY - startPosition.y, 2)
    );
    if (distance < 5 && !isDragging) {
      onClickHandler(data);
    }
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUpForQuestion = (event, data) => {
    const distance = Math.sqrt(
      Math.pow(event.clientX - startPosition.x, 2) +
        Math.pow(event.clientY - startPosition.y, 2)
    );
    if (distance < 5 && !isDragging) {
      onClickHandler(event,data);
    }
  };

  if (category == "questions") {
    return (
      <div
        className={classes.question_container}
        onMouseDown={handleMouseDown}
        onMouseUp={(event) => handleMouseUpForQuestion(event, dataItem?.question?.text)}
        onMouseMove={handleMouseMove}
      >
        <p className={classes.question}>{dataItem?.question?.text}</p>
      </div>
    );
  } else {
    return (
      <>
        {cardType ? (
          <div
            className={`${classes.category_card_item} ${
              cardType === "cartoons" && classes.cartoon
            }`}
            // style={cardType === "exercise" ? { minHeight: "190px" } : null}
            onMouseDown={handleMouseDown}
            onMouseUp={(event) => handleMouseUp(event, dataItem?.id)}
            onMouseMove={handleMouseMove}
          >
            <div className={classes.category_card_item_image_container}>
              <img
                src={dataItem?.[imgurl]}
                alt={dataItem?.[name]}
                className={classes.category_card_item_image}
              />
              <button className={classes.play_btn}>
                {cardType === "games" ? "Play" : "Watch"}
              </button>
            </div>
            {cardType === "cartoons" && (
              <div className={classes.category_card_content}>
                <p className={classes.name}>{dataItem?.name}</p>
                <p className={classes.duration}>{dataItem?.duration}</p>
              </div>
            )}
          </div>
        ) : (
          <div
            className={classes.category_item}
            onMouseDown={handleMouseDown}
            onMouseUp={(event) => handleMouseUp(event, dataItem?.id)}
            onMouseMove={handleMouseMove}
          >
            <img
              src={dataItem?.[imgurl]}
              alt={dataItem?.[name]}
              className={classes.category_item_image}
            />
          </div>
        )}
      </>
    );
  }
};

export default CategoryItem;
