import React from "react";
import classes from "./CategoryNewsMenu.module.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const CategoryNewsMenu = ({ leftConstraint }) => {
  const navigate = useNavigate();

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <motion.div
      className={classes.top_menu_bar}
      drag="x"
      dragConstraints={{
        top: 0,
        left: leftConstraint == 0 ? leftConstraint : leftConstraint - 150,
        right: 0,
        bottom: 0,
      }}
      transition={{
        type: "spring",
        damping: 100,
        stiffness: 100,
      }}
    >
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app")}
      >
        <i className={`pi pi-arrow-left ${classes.back_icon}`}></i>
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=news")}
      >
        Top News
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() => navigateHandler("/app/category?cid=news&query=sports")}
      >
        Sports News
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() =>
          navigateHandler("/app/category?cid=news&query=entertainment")
        }
      >
        Entertainment News
      </button>
      <button
        type="button"
        className={classes.menu_buttons}
        onClick={() =>
          navigateHandler("/app/category?cid=news&query=technology")
        }
      >
        Technology News
      </button>
    </motion.div>
  );
};

export default CategoryNewsMenu;
