import React, { useEffect, useState } from "react";
import classes from "./ChatPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ChatApi,
  FetchBlogsApi,
  FetchCartoonsApi,
  FetchGamesApi,
  FetchYogaVideosApi,
  GetLatestNewsApi,
  RandomQuestionsApi,
  scrapGoogleDataApi,
  UserChatHistoryApi,
} from "../Api/api";
import { useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import { motion } from "framer-motion";
import SmallLoader from "../Components/Loaders/SmallLoader";
import { setData } from "../slices/dataSlice";
import CategoryTitle from "../Components/Category-Section/CategoryTitle";
import CategoryDragSection from "../Components/Category-Section/CategoryDragSection";
import Button from "../Components/Button";
import CategoryNews from "../Components/Category-Section/CategoryNews";
import ChatBox from "../Components/Chat-Box/ChatBox";
import ChatInputBox from "../Components/Chat-Box/ChatInputBox";
import moment from "moment-timezone";
import ChatVoiceModal from "../Components/Chat-Box/ChatVoiceModal";
import CategoryBlogs from "../Components/Category-Section/CategoryBlogs";
import UserLocation from "../Components/UserLocation";

const ChatPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const { data } = useSelector((state) => state?.dataSlice);
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);

  const [locationPermission, setLocationPermission] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const [chatHistoryLoading, setChatHistoryLoading] = useState(true);
  const [aiChatLoading, setAiChatLoading] = useState(false);

  const [news, setNews] = useState([]);
  const [newsLoading, setNewsLoading] = useState(true);

  // const [cartoons, setCartoons] = useState([]);
  // const [cartoonLoading, setCartoonLoading] = useState(true);

  const [games, setGames] = useState([]);
  const [gameLoading, setGameLoading] = useState(true);

  const [yogaVideos, setYogaVideos] = useState([]);
  const [yogaVideosLoading, setYogaVideosLoading] = useState(true);

  const [questions, setQuestions] = useState([]);

  const [questionsLoading, setQuestionsLoading] = useState(true);

  const [newsActive, setNewsActive] = useState({});

  const [chatVoiceModal, setChatVoiceModal] = useState(false);

  // const [blogLoading, setBlogLoading] = useState(true);
  // const [blogs, setBlogs] = useState([]);

  const handleMessageSubmit = async (e, query) => {
    e.preventDefault();
    setAiChatLoading(true);
    if (query) {
      setMessages([
        {
          request: JSON.stringify(query),
          response: "",
          user_id: user?.id,
          msisdn: user?.msisdn,
        },
      ]);
    } else {
      setMessages([
        {
          request: JSON.stringify(message),
          response: "",
          user_id: user?.id,
          msisdn: user?.msisdn,
        },
      ]);
    }

    setMessage("");
    try {
      const response = await ChatApi(
        {
          user_id: user?.id,
          msisdn: user?.msisdn,
          chat_id: null,
          message: query ? query : message,
          latitude: latitude,
          longitude: longitude,
          locationPermission: locationPermission,
        },
        user?.token
      );
      let newMsgArray = [response?.data?.result];

      setMessages(newMsgArray);
      setAiChatLoading(false);
      navigate(`/app/${response?.data?.result?.chat_id}`);
    } catch (error) {
      setAiChatLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    fetchChatHistory();
    setNewsActive({});
    // fetchBlogs();
  }, []);

  // const fetchBlogs = async () => {
  //   try {
  //     setBlogLoading(true);
  //     const response = await FetchBlogsApi(user?.token);
  //     setBlogs(response?.data?.result);
  //     setBlogLoading(false);
  //   } catch (error) {
  //     setBlogLoading(false);
  //     toast.error(
  //       error?.response?.data?.err ||
  //         error?.data?.err ||
  //         error?.message ||
  //         error
  //     );
  //   }
  // };

  useEffect(() => {
    if (!data) {
      fetchRandomQuestions();
      fetchLatestNews();
      // fetchCartoons();
      fetchGames();
      fetchYogaVideos();
    } else {
      setQuestionsLoading(false);
      setNewsLoading(false);
      // setCartoonLoading(false);
      setGameLoading(false);
      setYogaVideosLoading(false);
    }
  }, [data]);

  useEffect(
    () => {
      if (
        !data &&
        games.length > 0 &&
        // cartoons.length > 0 &&
        yogaVideos.length > 0 &&
        news?.length > 0 &&
        questions.length > 0
      ) {
        dispatch(
          setData({
            games: games,
            // cartoons: cartoons,
            yogaVideos: yogaVideos,
            news: news,
            questions: questions,
          })
        );
      }
    },
    [data, games, yogaVideos, news, questions]
    // [data, games, cartoons, yogaVideos, news, questions]
  );

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const fetchRandomQuestions = async () => {
    try {
      setQuestionsLoading(true);
      const response = await RandomQuestionsApi();
      setQuestionsLoading(false);
      setQuestions(response?.data?.result);
    } catch (error) {
      setQuestionsLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const fetchLatestNews = async () => {
    try {
      setNewsLoading(true);
      const response = await GetLatestNewsApi(
        { datetime: moment().format("YYYY-MM-DD") },
        user?.token
      );
      setNews(response?.data?.result?.news);
      setNewsLoading(false);
    } catch (error) {
      setNewsLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  // const fetchCartoons = async () => {
  //   try {
  //     setCartoonLoading(true);
  //     const response = await FetchCartoonsApi(user?.token);
  //     setCartoonLoading(false);
  //     setCartoons(response?.data?.videos);
  //   } catch (error) {
  //     setCartoonLoading(false);
  //     toast.error(
  //       error?.response?.data?.message ||
  //         error?.data?.message ||
  //         error?.message ||
  //         error
  //     );
  //   }
  // };

  const fetchGames = async () => {
    try {
      setGameLoading(true);
      const response = await FetchGamesApi();
      const filteredGames = response?.data?.filter(
        (data) => data?.category === "Games"
      );
      setGames(filteredGames);
      setGameLoading(false);
    } catch (error) {
      setGameLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchYogaVideos = async () => {
    try {
      setYogaVideosLoading(true);
      const response = await FetchYogaVideosApi();
      setYogaVideos(response?.data.slice(0, 40));
      setYogaVideosLoading(false);
    } catch (error) {
      setYogaVideosLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const onClickQuestionHandler = (e, question) => {
    setMessage(question);
    setTimeout(() => {
      handleMessageSubmit(e, question);
    }, 200);
  };

  const onClickNewsHandler = (newsData, idx, navigateToNews) => {
    if (navigateToNews) {
      window.open(newsData?.url, "_blank");
      return;
    }
    if (newsActive?.title === newsData?.title && newsActive?.idx === idx) {
      setNewsActive({});
    } else {
      setNewsActive({ title: newsData?.title, idx: idx });
    }
  };

  // const onCartoonClickHandler = (id) => {
  //   navigate(`/app/category/play?cid=cartoons&id=${id}`);
  // };

  const onExerciseClickHandler = (id) => {
    navigate(`/app/category/play?cid=exercise&id=${id}`);
  };

  const onGameClickHandler = (id) => {
    navigate(`/app/category/play?cid=games&id=${id}`);
  };

  // const onClickBlogHandler = (blogId) => {
  //   navigate(`/app/blogs/${blogId}`);
  // };

  return (
    <UserLocation
      setLatitude={setLatitude}
      setLongitude={setLongitude}
      setLocationPermission={setLocationPermission}
    >
      <Layout
        expand={expand}
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={null}
      >
        <Navbar
          chatHistoryLoading={chatHistoryLoading}
          chatHistory={chatHistory}
          chat_id={null}
        />

        <div className={classes.chat_container}>
          {messages.length <= 0 ? (
            <div className={classes.chat_input_output_container}>
              <div className={classes.main_intro_section}>
                <motion.div className={classes.section_categories}>
                  <CategoryTitle title="Try Me" />
                  {questionsLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryDragSection
                      data={data?.questions ? data?.questions : questions}
                      dataItemKeys={{ imgurl: "", name: "" }}
                      category="questions"
                      onClickHandler={onClickQuestionHandler}
                      slice={true}
                    />
                  )}
                  <CategoryTitle title="Explore News">
                    <Button
                      text="Explore More"
                      icon="pi pi-arrow-right"
                      onBtnClick={() => navigate("/app/category?cid=news")}
                    />
                  </CategoryTitle>
                  {newsLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryNews
                      data={data?.news ? data?.news : news}
                      onClickHandler={onClickNewsHandler}
                      slice={true}
                      newsActive={newsActive}
                    />
                  )}
                  {/* <CategoryTitle title="Explore Cartoons">
                    <Button
                      text="Explore More"
                      icon="fa-solid fa-arrow-right"
                      onBtnClick={() => navigate("/app/category?cid=cartoons")}
                    />
                  </CategoryTitle> */}
                  {/* {cartoonLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryDragSection
                      data={!data ? cartoons : data?.cartoons}
                      dataItemKeys={{ imgurl: "imgurl", name: "name" }}
                      slice={true}
                      cardType="cartoons"
                      onClickHandler={onCartoonClickHandler}
                    />
                  )} */}
                  <CategoryTitle title="Explore Games">
                    <Button
                      text="Explore More"
                      icon="pi pi-arrow-right"
                      onBtnClick={() => navigate("/app/category?cid=games")}
                    />
                  </CategoryTitle>
                  {gameLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryDragSection
                      data={!data ? games : data?.games}
                      dataItemKeys={{ imgurl: "img_url", name: "game_name" }}
                      slice={true}
                      onClickHandler={onGameClickHandler}
                      cardType="games"
                    />
                  )}
                  {/* <CategoryTitle title="Explore Blogs">
                    <Button
                      text="Explore More"
                      icon="fa-solid fa-arrow-right"
                      onBtnClick={() => navigate("/app/blogs")}
                    />
                  </CategoryTitle>
                  {blogLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryBlogs
                      data={blogs}
                      onClickHandler={onClickBlogHandler}
                      slice={false}
                    />
                  )}{" "} */}
                  <CategoryTitle title="Explore Exercises">
                    <Button
                      text="Explore More"
                      icon="pi pi-arrow-right"
                      onBtnClick={() => navigate("/app/category?cid=exercise")}
                    />
                  </CategoryTitle>
                  {yogaVideosLoading ? (
                    <div className={classes.loader_container}>
                      <SmallLoader />
                    </div>
                  ) : (
                    <CategoryDragSection
                      data={!data ? yogaVideos : data?.yogaVideos}
                      dataItemKeys={{ imgurl: "imageFiveUrl", name: "name" }}
                      slice={true}
                      cardType="exercise"
                      onClickHandler={onExerciseClickHandler}
                    />
                  )}
                </motion.div>
              </div>
            </div>
          ) : (
            <ChatBox
              aiChatLoading={aiChatLoading}
              chatLoading={false}
              messages={messages}
            />
          )}

          <ChatInputBox
            message={message}
            handleMessageSubmit={handleMessageSubmit}
            setMessage={setMessage}
            setChatVoiceModal={setChatVoiceModal}
          />

          <ChatVoiceModal
            setChatVoiceModal={setChatVoiceModal}
            chatVoiceModal={chatVoiceModal}
            handleMessageSubmit={handleMessageSubmit}
          />
        </div>
      </Layout>
    </UserLocation>
  );
};

export default ChatPage;
