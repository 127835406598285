import React from "react";
import classes from "./CategoryTitle.module.css"

const CategoryTitle = ({ title, children }) => {
  return (
    <div className={classes.category_title}>
      <p className={classes.category_title_heading}>{title}</p>
      {children}
    </div>
  );
};

export default CategoryTitle;
