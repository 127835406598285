import React, { useEffect, useState, useRef } from "react";
import classes from "./CategoryPlayPage.module.css";
import CategoryMenu from "../Components/CategoryMenu";
import { toast } from "react-toastify";
import {
  FetchCartoonsApi,
  FetchGamesApi,
  FetchYogaVideosApi,
  UserChatHistoryApi,
} from "../Api/api";
import { useSelector } from "react-redux";
import Layout from "../Components/Layout";
import Navbar from "../Components/Navbar";
import dashjs from "dashjs";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import SmallLoader from "../Components/Loaders/SmallLoader";
import CategoryTitle from "../Components/Category-Section/CategoryTitle";
import CategoryDragSection from "../Components/Category-Section/CategoryDragSection";

const CategoryPlayPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const { user } = useSelector((state) => state?.userSlice);
  const { data } = useSelector((state) => state?.dataSlice);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataLoading, setCategoryDataLoading] = useState(true);
  const [category, setCategory] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [gameUrl, setGameUrl] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();

  const iframeRef = useRef(null);

  useEffect(() => {
    fetchChatHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const id = urlParams.get("id");

    // const allowedCategories = ["games", "exercise", "news", "cartoons"];
    const allowedCategories = ["games", "exercise", "news"];
    if (!id) {
      navigate("/app");
      return;
    }
    if (allowedCategories.includes(cid)) {
      // if (cid === "cartoons") {
      //   fetchCartoons(id);
      // }
      if (cid === "exercise") {
        fetchExercise(id);
      }
      if (cid === "games") {
        fetchGames(id);
      }
      setCategory(cid);
    } else {
      navigate("/app");
    }
  }, [window.location.search, data]);

  const fetchChatHistory = async () => {
    try {
      setChatHistoryLoading(true);
      const response = await UserChatHistoryApi(
        { msisdn: user?.msisdn },
        user?.token
      );
      setChatHistory(response?.data?.result);
      setChatHistoryLoading(false);
    } catch (error) {
      setChatHistoryLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  // const fetchCartoons = async (id) => {
  //   try {
  //     setCategoryDataLoading(true);
  //     const response = await FetchCartoonsApi(user?.token);
  //     const resultArray = [];
  //     const uniqueSubCategoryIds = Array.from(
  //       new Set(response?.data?.categories.map((item) => item.sub_cat_id))
  //     );

  //     uniqueSubCategoryIds.forEach((subCategoryId) => {
  //       const filteredVideos = response?.data?.videos.filter(
  //         (video) => video.sub_category_id == subCategoryId
  //       );

  //       const categoryName = response?.data?.categories.find(
  //         (item) => item.sub_cat_id == subCategoryId
  //       )?.name;
  //       resultArray.push({
  //         videos: filteredVideos,
  //         category: categoryName,
  //       });
  //     });

  //     const getVideoById = response?.data?.videos.filter(
  //       (video) => video.id == id
  //     );

  //     if (getVideoById.length > 0) {
  //       setVideoUrl(getVideoById[0]?.vurl);
  //     }

  //     setCategoryData(resultArray);
  //     setCategoryDataLoading(false);
  //   } catch (error) {
  //     setCategoryDataLoading(false);
  //     toast.error(
  //       error?.response?.data?.message ||
  //         error?.data?.message ||
  //         error?.message ||
  //         error
  //     );
  //   }
  // };

  const fetchExercise = async (id) => {
    try {
      setCategoryDataLoading(true);
      const response = await FetchYogaVideosApi();
      setCategoryDataLoading(false);
      let resultArray = [];
      resultArray.push(
        {
          videos: response?.data?.slice(0, 7),
          category: "Exercise",
        },
        {
          videos: response?.data?.slice(8, 16),
          category: "Stay Healthy",
        },
        {
          videos: response?.data?.slice(16, 24),
          category: "Fit & Fine",
        },
        {
          videos: response?.data?.slice(24, 32),
          category: "Fit",
        }
      );
      setCategoryData(resultArray);

      const getVideoById = response?.data?.filter((video) => video.id == id);
      if (getVideoById.length > 0) {
        setVideoUrl(getVideoById[0]?.vurl_dash);
      }
    } catch (error) {
      setCategoryDataLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchGames = async (id) => {
    try {
      setCategoryDataLoading(true);
      const response = await FetchGamesApi();
      const filteredGames = response?.data?.filter(
        (data) => data?.category === "Games"
      );
      setCategoryDataLoading(false);
      let resultArray = [];
      resultArray.push(
        {
          videos: filteredGames.slice(0, 7),
          category: "Top Games",
        },
        {
          videos: filteredGames.slice(7, 11),
          category: "Mind Refreshing",
        }
      );
      setCategoryData(resultArray);

      const getGameById = response?.data.filter((game) => game.id == id);

      if (getGameById.length > 0) {
        setGameUrl(getGameById[0]?.game_url);
      }
    } catch (error) {
      setCategoryDataLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const [leftConstraint, setLeftConstraint] = useState();
  useEffect(() => {
    const updateConstraints = () => {
      if (window.innerWidth < 750) {
        setLeftConstraint(-300);
      } else {
        setLeftConstraint(0);
      }
    };

    updateConstraints();
    window.addEventListener("resize", updateConstraints);

    return () => {
      window.removeEventListener("resize", updateConstraints);
    };
  }, []);

  const handleFullScreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        // Firefox
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        // IE/Edge
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.mozFullScreenElement) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitFullscreenElement) {
      // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msFullscreenElement) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  useEffect(() => {
    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("MSFullscreenChange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener("MSFullscreenChange", onFullScreenChange);
    };
  }, []);

  const onClickHandler = (id) => {
    navigate(`/app/category/play?cid=${category}&id=${id}`);
  };

  return (
    <Layout
      expand={expand}
      chatHistoryLoading={chatHistoryLoading}
      chatHistory={chatHistory}
      chat_id={null}
      style={{ maxHeight: "unset" }}
    >
      <Navbar
        chatHistoryLoading={chatHistoryLoading}
        chatHistory={chatHistory}
        chat_id={null}
      />
      <div className={classes.category_main_container}>
        <CategoryMenu leftConstraint={leftConstraint} />

        {categoryDataLoading ? (
          <SmallLoader />
        ) : (
          <>
            {category == "games" && gameUrl ? (
              <div className={classes.iframe_container}>
                <button
                  type="button"
                  className={classes.exit_btn}
                  onClick={() => navigate("/app")}
                >
                  <i className={`pi pi-arrow-left ${classes.exit_arrow}`}></i>
                  {/* <i
                    className={`fa-solid fa-arrow-left ${classes.exit_arrow}`}
                  ></i> */}
                </button>
                <button
                  onClick={handleFullScreen}
                  type="button"
                  className={classes.full_screen_btn}
                >
                  <i
                    className={`pi pi-expand ${classes.expand_icon}`}
                  ></i>
                </button>

                {isFullScreen && (
                  <button
                    onClick={handleExitFullScreen}
                    className={classes.exitFullScreenButton}
                  >
                    Exit Fullscreen
                  </button>
                )}

                <iframe
                  ref={iframeRef}
                  src={gameUrl}
                  allow="encrypted-media"
                  frameborder="0"
                  className={classes.frame}
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <div className={classes.video_container}>
                <ReactPlayer
                  url={videoUrl}
                  controls={true}
                  playing={true}
                  loop={true}
                  muted
                  playsinline={true}
                  className={`${classes.video_player}`}
                  width="100%"
                  //   light={
                  //     <img
                  //       className={classes.thumbnail}
                  //       src={`https://dolcepanz.s3.af-south-1.amazonaws.com/images/image_1650709314900.jpg`}
                  //       alt="Thumbnail"
                  //     />
                  //   }
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "anonymous",
                        // controlsList:
                        //   "nodownload nofullscreen noremoteplayback",
                      },
                    },
                    forceDASH: true,
                    dashOptions: {
                      debug: {
                        logLevel: dashjs.Debug.LOG_LEVEL_INFO,
                      },
                    },
                  }}
                />
              </div>
            )}

            {categoryData?.map((dataItem, idx) => {
              return (
                <React.Fragment key={idx}>
                  <CategoryTitle title={dataItem?.category} />
                  <CategoryDragSection
                    slice={false}
                    data={dataItem?.videos}
                    dataItemKeys={
                      category === "cartoons"
                        ? { imgurl: "imgurl", name: "name" }
                        : category === "exercise"
                        ? { imgurl: "imageFiveUrl", name: "name" }
                        : { imgurl: "img_url", name: "game_name" }
                    }
                    cardType={category}
                    onClickHandler={onClickHandler}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
    </Layout>
  );
};

export default CategoryPlayPage;
